// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-4-g-lte-products-tsx": () => import("./../../../src/pages/4G-LTE-Products.tsx" /* webpackChunkName: "component---src-pages-4-g-lte-products-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-g-products-tsx": () => import("./../../../src/pages/5G-Products.tsx" /* webpackChunkName: "component---src-pages-5-g-products-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-haps-tsx": () => import("./../../../src/pages/HAPS.tsx" /* webpackChunkName: "component---src-pages-haps-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

